import React, { Component } from "react"

export enum IconButtonType {
  HAMBURGER = "hamburger",
  CLOSE = "close",
  LEFTNAV = "leftNav",
}

interface Props {
  type: IconButtonType
  className?: string
  background?: string
  onClick?: (e: any) => void
}

export default class IconButton extends Component<Props> {
  render() {
    let path = ""
    switch (this.props.type) {
      case "hamburger":
        path = "M4 6h16M4 12h16M4 18h16"
        break
      case "close":
        path = "M6 18L18 6M6 6l12 12"
        break
      case "leftNav":
        path = "M4 6h16M4 12h8m-8 6h16"
    }

    if (path) {
      let defaultClassName =
        "rounded-md p-2 inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-inset"
      if (this.props.className) {
        defaultClassName += ` ${this.props.className}`
      }
      if (this.props.background) {
        defaultClassName += ` ${this.props.background}`
      } else {
        defaultClassName +=
          " text-gray-400 hover:text-gray-500 hover:bg-gray-100 bg-white focus:ring-arusgreen-500"
      }

      return (
        <button
          type="button"
          className={defaultClassName}
          aria-expanded="false"
          onClick={this.props.onClick}
        >
          <span className="sr-only">Open menu</span>
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={path}
            />
          </svg>
        </button>
      )
    } else {
      return null
    }
  }
}
