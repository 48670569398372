import { Link, navigate } from "gatsby"
import React, { Component } from "react"
import Logo from "../../images/favicon.png"

export default class Footer extends Component {
  handleNavigation = (link: string) => {
    navigate(link)
  }
  render() {
    return (
      <footer
        id="contact"
        className="bg-white border-t"
        aria-labelledby="footerHeading"
      >
        <h2 id="footerHeading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-1 xl:col-span-1">
              <img
                className="h-10 hover:cursor-pointer"
                src={Logo}
                alt="Blixify"
                onClick={this.handleNavigation.bind(this, "/")}
              />
              <p className="text-gray-500 text-base font-bold">
                Contact Email : info@blixify.co
              </p>
              <p className="text-gray-500 text-base">
                Simplifying Digital Products Development
              </p>
              <p className="text-gray-500 text-base pt-4 font-medium">
                Blixify Pte Ltd - 202218160R (Singapore)
              </p>
              <p className="text-gray-500 text-sm">
                10 Anson Road, #22-06, International Plaza Singapore 079903
              </p>
              <p className="text-gray-500 text-base pt-4 font-medium">
                Blixify Sdn Bhd - 1546830T (Malaysia)
              </p>
              <p className="text-gray-500 text-sm">
                33-1-123A, Prangin Mall, Jalan Dr Lim Chwee Leong 10100
                Georgetown Penang
              </p>
            </div>
            <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Company
                  </h3>
                  <ul className="mt-4 space-y-4">
                    <li>
                      <Link
                        to={"/aboutUs"}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services"
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/portfolio"
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        Portfolio
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-200 pt-8">
            <p className="text-base text-gray-400 xl:text-center">
              &copy; 2024 Blixify Pte Ltd. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    )
  }
}
