import { navigate } from "gatsby"
import React, { Component } from "react"
import Button from "../base/Button"
interface Props {
  title?: string
  description?: string
  extraLink?: string
  extraButton?: string
}

export default class Hero extends Component<Props> {
  handleNavigation = (link: string) => {
    navigate(link)
  }

  render() {
    return (
      <main className="mt-16 mb-24 mx-auto max-w-7xl px-4 relative">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 md:text-5xl">
            {this.props.title ? (
              <span className="block text-indigo-600 xl:inline">
                {this.props.title}
              </span>
            ) : (
              <>
                <span className="block xl:inline">Simplifying </span>
                <span className="block text-indigo-600 xl:inline">
                  Digital Products Development
                </span>
              </>
            )}
          </h1>
          <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            {this.props.description
              ? this.props.description
              : `Hey there 👋, we are Blixify, a software house based in Penang/Singapore
            🏝️ which philosophically believe in minimalistic and user-centric
            products 🌱. We use agile methodology (Build-Measure-Learn) in
            deploying products 📊.`}
          </p>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <Button
              text="About Us"
              type="normal"
              className="w-full mx-2 my-4"
              onClick={this.handleNavigation.bind(this, "/aboutUs")}
            />
            <Button
              text="Contact Us"
              type="normal"
              className="w-full mx-2 my-4"
              onClick={this.handleNavigation.bind(this, "#contact")}
            />
            {this.props.extraLink && this.props.extraButton && (
              <Button
                text={this.props.extraButton}
                type="light"
                className="w-full mt-3 rounded-md shadow sm:mt-0 sm:ml-3"
                onClick={this.handleNavigation.bind(this, this.props.extraLink)}
              />
            )}
          </div>
        </div>
      </main>
    )
  }
}
