import { Link } from "gatsby"
import React, { Component } from "react"
import LogoBlack from "../../images/favicon.png"
import IconButton, { IconButtonType } from "./IconButton"

export default class CustomHeader extends Component {
  state = {
    mobileMenu: false,
  }

  handleOpenMobileMenu = () => {
    this.setState({
      mobileMenu: !this.state.mobileMenu,
    })
  }

  renderLogo = () => {
    return (
      <Link to="/">
        <img className="h-12 w-auto sm:h-10" src={LogoBlack} alt="Blixify" />
      </Link>
    )
  }

  renderHeaderLink = (type: string) => {
    let linkNav = ""
    let linkTitle = ""

    switch (type) {
      case "aboutUs":
        linkTitle = "About Us"
        linkNav = "/aboutUs"
        break
      case "services":
        linkTitle = "Services"
        linkNav = "/services"
        break
      case "portfolio":
        linkTitle = "Portfolio"
        linkNav = "/portfolio"
        break
      case "contact":
        linkTitle = "Contact Us"
        linkNav = "#contact"
    }

    if (linkNav && linkTitle) {
      return (
        <Link to={linkNav}>
          <span className="font-medium text-gray-500 hover:text-gray-900">
            {linkTitle}
          </span>
        </Link>
      )
    } else {
      return null
    }
  }

  render() {
    return (
      <div className="relative py-6 z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <nav
            className="relative flex items-center justify-between sm:h-10 md:justify-center"
            aria-label="Global"
          >
            <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
              <div className="flex items-center justify-between w-full md:w-auto">
                <span className="sr-only">Blixify</span>
                {this.renderLogo()}
                <div className="-mr-2 flex items-center md:hidden">
                  <IconButton
                    onClick={this.handleOpenMobileMenu}
                    type={IconButtonType.HAMBURGER}
                  />
                </div>
              </div>
            </div>
            <div className="hidden md:flex md:space-x-10">
              {this.renderHeaderLink("aboutUs")}
              {this.renderHeaderLink("portfolio")}
              {this.renderHeaderLink("services")}
              {this.renderHeaderLink("contact")}
            </div>
          </nav>
        </div>

        {this.state.mobileMenu && (
          <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-40">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>{this.renderLogo()}</div>
                  <div className="-mr-2">
                    <IconButton
                      onClick={this.handleOpenMobileMenu}
                      type={IconButtonType.CLOSE}
                    />
                  </div>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div>{this.renderHeaderLink("aboutUs")}</div>
                <div>{this.renderHeaderLink("portfolio")}</div>
                <div>{this.renderHeaderLink("services")}</div>
                <div>{this.renderHeaderLink("contact")}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}
