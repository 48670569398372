import React, { Component } from "react"

interface Props {
  text: string
  type: string
  className?: string
  onClick?: (e: any) => void
}

export default class Button extends Component<Props> {
  render() {
    let defaultClassName = `block rounded-md border border-transparent px-5 py-3 text-base font-medium shadow focus:outline-none focus:ring-2 focus:ring-offset-2 sm:px-10`

    if (this.props.className) {
      defaultClassName += ` ${this.props.className}`
    }

    if (this.props.type) {
      switch (this.props.type) {
        case "normal":
          defaultClassName +=
            " text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-600"
          break
        case "light":
          defaultClassName +=
            " text-indigo-600 bg-white hover:bg-gray-50 focus:ring-indigo-600"
          break
      }
    }

    return (
      <button
        type="button"
        className={defaultClassName}
        onClick={this.props.onClick}
      >
        {this.props.text}
      </button>
    )
  }
}
