import {
  DevicePhoneMobileIcon,
  SignalIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/solid"
import { Container, Grid, IconList, LeftContent } from "blixify-ui-web/lib"
import { navigate } from "gatsby"
import React, { Component } from "react"
import Button from "../components/base/Button"
import Header from "../components/base/CustomHeader"
import Footer from "../components/base/Footer"
import SEO from "../components/base/seo"
import Hero from "../components/marketing/Hero"
import Services from "../images/about_blixify.png"
import SolAgri from "../images/home_agri.jpg"
import SolInventory from "../images/home_inventory.jpg"
import SolLogistics from "../images/home_logistic.jpg"

export const servicesContent = [
  "We specialises in the development of Full-Stack Web, Mobile Applications and Internet of Things (IoT) services. Some common use cases of our services are allowing clients to gather valuable data insights, increase operational efficiencies and improve customer experiences.​ ",
]
export const servicesContentList = [
  {
    name: "Full-Stack Web Development",
    content:
      "We craft mobile-friendly web app applications, covering modules such as ecommerce, transport management, and content management to empower your business",
    icon: <Square3Stack3DIcon className="w-6 h-6" />,
  },
  {
    name: "Mobile App Development",
    content:
      "Unlocking the potential of mobile technology, we specialize in crafting cross-platform mobile applications tailored for both Android and iOS. Bringing your app ideas to life",
    icon: <DevicePhoneMobileIcon className="w-6 h-6" />,
  },
  {
    name: "Internet of Things (IoT)",
    content:
      "From integrating a diverse range of off-the-shelf sensors to seamlessly connecting with custom machines via protocols like RS485, we enable intelligent connectivity for your business.",
    icon: <SignalIcon className="w-6 h-6" />,
  },
]

const industriesData = [
  {
    id: "1",
    title: "Agriculture",
    description:
      "In the agriculture industry, we improve crop yield through IoT integration. Our solutions utilize sensors to monitor soil conditions and weather patterns. With customized alarm capabilities, farmers can trigger actions like irrigation precisely when needed, optimizing crop yields and driving sustainable farming practices.",
    tags: ["IOT", "Smart Farming"],
    imageUrl: SolAgri,
  },
  {
    id: "2",
    title: "Logistics",
    description:
      "By leveraging driver GPS data, we calculate fuel consumption and carbon emissions, empowering sustainable practices. Our intelligent route algorithms optimize deliveries, saving time and resources. Additionally, users can easily track their logistics status in real-time, ensuring transparency and streamlined operation",
    tags: ["Web & Mobile", "Transport System"],
    imageUrl: SolLogistics,
  },
  {
    id: "3",
    title: "Asset Management",
    description:
      "Our system monitors the status and defects of each asset, ensuring comprehensive accountability and traceability. Leveraging cutting-edge technologies like cameras, we provide real-time insights and visual evidence for enhanced security.",
    tags: ["IOT", "Web & App", "Inventory Tracking"],
    imageUrl: SolInventory,
  },
]

export const renderSVG = () => {
  return (
    <div
      className="hidden md:block md:absolute md:inset-y-0 md:h-full md:w-full"
      aria-hidden="true"
    >
      <div className="relative h-full max-w-7xl mx-auto">
        <svg
          className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="f210dbf6-a58d-4871-961e-36d5016a0f49"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
          />
        </svg>
        <svg
          className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
          />
        </svg>
      </div>
    </div>
  )
}

export default class Home extends Component {
  handleNavigation = (link: string) => {
    navigate(link)
  }

  render() {
    return (
      <div>
        <SEO
          title="Blixify - Home"
          description="Blixify is a software house that specializes in developing minimalistic and user-centric digital products. Through the agile methodology of Build, Measure, Learn, we ensure continuous improvement. Our proprietary dashboard engine empowers users to effortlessly create customized forms, visualize data with charts, and automate workflows, catering to diverse industries."
        />
        <div className="relative bg-gray-50 overflow-hidden">
          <Header />
          {renderSVG()}
          <Hero />
        </div>
        <Container bgColor="bg-white">
          <LeftContent
            className="mt-10 mb-14"
            title="Our Services"
            contentList={servicesContent}
            callToAction={<IconList iconList={servicesContentList} />}
            image={Services}
          />
          <div className="flex flex-col items-center mt-24 mx-auto max-w-7xl px-4 mb-20">
            <h2 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl mb-2">
              Industry Case Studies
            </h2>
            <p className="mt-3 max-w-md mx-auto text-md text-gray-500 sm:text-md md:mt-5 md:text-md md:max-w-3xl">
              We are fortunate to be able to work on solutions for these
              industries. Contact us for demo 🌆
            </p>
            <Grid
              data={industriesData}
              loading={false}
              hidePagination={true}
              paginationType="page"
            />
            <Button
              text="Visit Other Projects"
              type="normal"
              className="mt-5"
              onClick={this.handleNavigation.bind(this, "/portfolio")}
            />
          </div>
        </Container>
        <Footer />
      </div>
    )
  }
}
